<template>
  <div class="p-grid crud-demo">
    <div class="p-col-12">
      <div class="card layout-toppanel">
        <p>Se Gestiona todas las etiquetas o secciones de la documentación.</p>
      </div>
      <div class="card">
        <Toast />
        <Toolbar class="p-mb-4">
          <template v-slot:left>
            <Button
              label="Nuevo"
              icon="pi pi-plus"
              class="p-button-success p-mr-2"
              @click="openNew"
            />
          </template>

          <template v-slot:right>
            <Button
              label="Exportar"
              icon="pi pi-upload"
              class="p-button-help"
              @click="exportCSV($event)"
            />
          </template>
        </Toolbar>

        <DataTable
          ref="dt"
          :value="products"
          v-model:selection="selectedProducts"
          dataKey="id"
          :paginator="true"
          :rows="10"
          v-model:filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} etiquetas"
          responsiveLayout="scroll"
          :globalFilterFields="['nombre']"
        >
          <template #header>
            <div
              class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between"
            >
              <h5 class="p-m-0">Administrar Etiquetas</h5>
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Buscar..."
                />
              </span>
            </div>
          </template>

          <Column selectionMode="single" headerStyle="width: 3rem"></Column>
          <Column field="id" header="id" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">id</span>
              {{ slotProps.data.id }}
            </template>
          </Column>

          <Column field="nombre" header="Nombre" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Nombre</span>
              {{ formatCurrency(slotProps.data.nombre) }}
            </template>
          </Column>

          <Column headerStyle="width: 120px">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success p-mr-2"
                @click="editProduct(slotProps.data)"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning"
                @click="confirmDeleteProduct(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>

        <Dialog
          v-model:visible="productDialog"
          :style="{ width: '550px' }"
          header="Detalles de la Etiqueta"
          :modal="true"
          class="p-fluid"
        >
          <div class="p-field">
            <label for="name">Nombre</label>
            <InputText
              id="name"
              v-model.trim="product.nombre"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !product.nombre }"
            />
            <small class="p-invalid" v-if="submitted && !product.nombre"
              >Nombre es requerido.</small
            >
          </div>

          <template #footer>
            <Button
              label="Cancelar"
              icon="pi pi-times"
              class="p-button-text"
              @click="hideDialog"
            />
            <Button
              label="Guardar"
              icon="pi pi-check"
              class="p-button-text"
              @click="saveProduct"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="confirmation-content">
            <i
              class="pi pi-exclamation-triangle p-mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Esta seguro de querer borrar la etiqueta:
              <b>{{ product.nombre }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteProduct"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { FilterMatchMode, FilterOperator } from "primevue/api";

export default {
  data() {
    return {
      products: null,
     
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      product: {},
      selectedProducts: null,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nombre: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      submitted: false,
      action: "",
    };
  },
  productService: null,
  created() {
    // this.productService = new ProductService();
  },
  mounted() {
    this.product = this.model;
    this.listarData();
  },
  methods: {
    
    listarData(e) {
      this.isBusy = true;
      this.$swal({
        width: 370,
        text: "Cargando....",
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: () => {
          this.$swal.showLoading();
          this.$store
            .dispatch("gsdialogo/ETIQUETA_LIST", e === undefined ? true : e)
            .then((res) => {
              this.isBusy = false;
              this.$swal.close();
              this.products = res === undefined ? [] : res;
            })
            .catch((error) => {
              this.$swal.close();
              this.isBusy = false;
              if (
                error.response.status == 401 ||
                error.response.status == 500
              ) {
                this.$swal(
                  error.response.data.errorMessage,
                  "Ud. no tiene permitido esta función",
                  "warning"
                );
              }
            });
        },
      }).then((result) => {
        console.log(result);
      });
    },
    formatCurrency(value) {
      if (value.length >= 80) {
        return value.substring(1, 80) + "...";
      } else {
        return value;
      }
    },
    openNew() {
      this.action = "gsdialogo/ETIQUETA_SAVE";
      this.product = {
        id: 0,
        nombre: "",
        estado: true,
        userRegistro: 0,
      };
      this.submitted = false;
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    saveProduct() {
      this.submitted = true;
      let modelo = this.product;

      if (this.product.nombre.trim()) {
        this.isBusy = true;

        this.$swal({
          width: 370,
          text: "Cargando...",
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          onOpen: () => {
            this.$swal.showLoading();
            this.$store
              .dispatch(this.action, modelo)
              .then((res) => {
                this.isBusy = false;
                this.$swal.close();
                this.listarData();
                this.$toast.add({
                  severity: "success",
                  summary: "Successful",
                  detail: "Etiqueta Actualizado id: " + res.id,
                  life: 3000,
                });
              })
              .catch((error) => {
                this.$swal.close();
                this.isBusy = false;
                if (
                  error.response.status == 401 ||
                  error.response.status == 500
                ) {
                  this.$swal(
                    error.response.data.errorMessage,
                    "Ud. no tiene permitido esta función",
                    "warning"
                  );
                }
              });
          },
        }).then((result) => {
          console.log(result);
        });

        this.productDialog = false;
      }
    },
    editProduct(product) {
      this.action = "gsdialogo/ETIQUETA_UPDATE";
      this.product = { ...product };

      this.productDialog = true;
    },
    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    deleteProduct() {
      this.products = this.products.filter((val) => val.id !== this.product.id);
      let id = this.product.id;
      this.deleteProductDialog = false;
      this.product = {};

      this.$swal({
        width: 370,
        text: "Borrando...",
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: () => {
          this.$swal.showLoading();
          this.$store
            .dispatch("gsdialogo/ETIQUETA_DELETE", id)
            .then((res) => {
              this.isBusy = false;
              this.$swal.close();
              this.listarData();
              this.$toast.add({
                severity: "success",
                summary: "Successful",
                detail: "Etiqueta borrado id: " + res.id,
                life: 3000,
              });
            })
            .catch((error) => {
              this.$swal.close();
              this.isBusy = false;
              if (
                error.response.status == 401 ||
                error.response.status == 500
              ) {
                this.$swal(
                  error.response.data.errorMessage,
                  "Ud. no tiene permitido esta función",
                  "warning"
                );
              }
            });
        },
      }).then((result) => {
        console.log(result);
      });
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
  },
  computed: { ...mapGetters({ model: "gsdialogo/getModelEtiq" }) },
};
</script>
<style scoped lang="scss">
.calendar-body {
  height: 50px;
}
</style>
